<template>
  <div id="app">
    <UiToastList/>
    <div class="app-wrapper">
      <transition name="fade">
        <router-view></router-view>
      </transition>
    </div>
  </div>
</template>

<script>
import UiToastList from '@/components/UiToastList'

export default {
  name: 'AppLayout',
  components: {
    UiToastList
  }
}
</script>
