/**
 * import and init third party
 * components/validators and other dependencies
 */

import Vue from 'vue'
import * as THREE from 'three'
import {
  TweenLite
} from 'gsap'
import {
  Camera_Positions
} from '@/.env'
THREE.OrbitControls = require('three-orbit-controls')(THREE)

export class Scene {
  camera
  scenes = []
  layer
  renderer
  controls = null
  isUserInteracting = false
  tooltipActive = false
  lon = 0
  lat = 0
  phi = 0
  theta = 0
  onPointerDownPointerX = 0
  onPointerDownPointerY = 0
  onPointerDownLon = 0
  onPointerDownLat = 0
  points = []
  emitSpriteHoverOut = true
  emitSpriteHoverIn = false
  langue
  position
  questionIndex

  constructor(ele,
    EntryPart1,
    AlMajless,
    AlMajless2,
    EntryPart2,
    HouseWell,
    InternalCourtyard,
    AlAhsaa,
    HistoricDocuments,
    DatesStorage,
    RoomBaiaa,
    BedroomAddar,
    WomensRoom,
    Kitchen) {
    this.container = ele
    this.EntryPart1 = EntryPart1
    this.AlMajless = AlMajless
    this.AlMajless2 = AlMajless2
    this.EntryPart2 = EntryPart2
    this.HouseWell = HouseWell
    this.InternalCourtyard = InternalCourtyard
    this.AlAhsaa = AlAhsaa
    this.HistoricDocuments = HistoricDocuments
    this.DatesStorage = DatesStorage
    this.RoomBaiaa = RoomBaiaa
    this.BedroomAddar = BedroomAddar
    this.WomensRoom = WomensRoom
    this.Kitchen = Kitchen
    this.raycaster = new THREE.Raycaster()
    this.renderer = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(window.innerWidth, window.innerHeight)
  }

  // Creating Sphere
  createSphere(
    textureMaterial = 'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/2294472375_24a3b8ef46_o.jpg'
  ) {
    const name = 'environmentSphere'
    const geometry = new THREE.SphereGeometry(100, 55, 55)
    const texture = new THREE.TextureLoader().load(textureMaterial)
    texture.depthWrite = false
    texture.wrapS = THREE.RepeatWrapping
    texture.repeat.x = -1
    texture.mapping = THREE.EquirectangularReflectionMapping
    const material = new THREE.MeshBasicMaterial({
      map: texture,
      side: THREE.DoubleSide
    })
    material.transparent = true
    const mesh = new THREE.Mesh(geometry, material)
    mesh.name = name
    return mesh
  }

  /* sendStats(typeS, detailS) {
     const data = {
       type: typeS,
       detail: detailS,
       version: localStorage.getItem('version'),
       SessionId: localStorage.getItem('userID')
     }
     ReportingService.sendReport(data)
     // console.log(res)
   }
 
   // making video play
   playVideo(
   ) {
     const video = document.getElementById('video')
     video.play()
   } */

  // Creating sprite
  addSprite(
    point = new THREE.Vector3(14, 1.9, -47),
    name = 'nextScene',
    popoverContent = 'content',
    texture = '/Mazars/info.png'
  ) {
    const map = new THREE.TextureLoader().load(texture)
    const spriteMaterial = new THREE.SpriteMaterial({
      map: map,
      alphaTest: 0.5
    })
    const sprite = new THREE.Sprite(spriteMaterial)
    sprite.name = name
    sprite.scale.multiplyScalar(2.5)
    sprite.popoverContent = popoverContent
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(20)
    )
    return sprite
  }

  // Function to move to another scene
  goToScene(sceneIndex) {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const position = Camera_Positions[sceneIndex]
    /* var Scene_Name = Scene_Names[sceneIndex].Scene_Name */
    console.log(position.position.x, position.position.y, position.position.z)
    /* this.sendStats('scene', Scene_Name) */

    /* const video = document.getElementById('video')
    if (typeof CHROMA_VIDEOS[sceneIndex] !== 'undefined') {
      video.setAttribute('src', CHROMA_VIDEOS[sceneIndex][this.langue])
    }
    video.pause()
    video.currentTime = 0 */
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites(sceneIndex)
      this.camera.zoom = 1
      TweenLite.to(this.camera, 0.3, {
        zoom: 2,
        onUpdate: () => {
          this.camera.updateProjectionMatrix()
        },
        onComplete: () => {
          this.currentScene = nextScene
          // const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          // newCurrentSceneSphere.material.opacity = 0
          this.camera.zoom = 2
          TweenLite.to(this.camera, 0.3, {
            zoom: 1,
            onUpdate: () => {
              this.camera.updateProjectionMatrix()
            }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(position.position.x, position.position.y, position.position.z)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // Function to move to the Next scene
  goToNextScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex + 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      this.camera.zoom = 1
      TweenLite.to(this.camera, 0.3, {
        zoom: 2,
        onUpdate: () => {
          this.camera.updateProjectionMatrix()
        },
        onComplete: () => {
          this.currentScene = nextScene
          // const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          // newCurrentSceneSphere.material.opacity = 0
          this.camera.zoom = 2
          TweenLite.to(this.camera, 0.3, {
            zoom: 1,
            onUpdate: () => {
              this.camera.updateProjectionMatrix()
            }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // Function to move to the previous scene
  goToPrevScene() {
    const environmentSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
    const currentSceneIndex = this.scenes.findIndex(s => s.uuid === this.currentScene.uuid)
    const nextScene = this.scenes[currentSceneIndex - 1] || this.scenes[0]

    this.container.removeEventListener('mousemove', this.onMouseMove, false)
    if (environmentSphere) {
      this.removeSprites()
      this.camera.zoom = 1
      TweenLite.to(this.camera, 0.3, {
        zoom: 2,
        onUpdate: () => {
          this.camera.updateProjectionMatrix()
        },
        onComplete: () => {
          this.currentScene = nextScene
          // const newCurrentSceneSphere = this.currentScene.children.find(s => s.name === 'environmentSphere')
          // newCurrentSceneSphere.material.opacity = 0
          this.camera.zoom = 2
          TweenLite.to(this.camera, 0.3, {
            zoom: 1,
            onUpdate: () => {
              this.camera.updateProjectionMatrix()
            }
          })
          environmentSphere.material.opacity = 1
          this.camera.position.set(-0.1, 0, 0)
          this.camera.updateProjectionMatrix()
          this.controls.update()
          this.container.addEventListener('mousemove', this.onMouseMove, false)
        }
      })
    }
  }

  // remove sprite after moving to another scene
  removeSprites = (sceneIndex) => {
    this.EventBus.$emit('i-got-hover-out')
    const sprites = this.currentScene.children.filter(o => o.type === 'Sprite')
    const nextScene = this.scenes[sceneIndex] || this.scenes[0]
    const nextSceneSprites = nextScene.children.filter(o => o.type === 'Sprite')
    sprites.map(s => {
      TweenLite.to(s.scale, 1, {
        x: 0,
        y: 0,
        z: 0,
        onComplete: () => {
          nextSceneSprites.map(ns => {
            TweenLite.to(ns.scale, 1, {
              x: 4,
              y: 4,
              z: 4,
              onComplete: () => {}
            })
          })
        }
      })
    })
  }

  /* clickedToQuizz(Q) {
    const questionIndex = Q
    console.log('QI', questionIndex)
    localStorage.setItem('questionIndex', questionIndex)
    return questionIndex
  } */

  // making videoTexture into plane geometry to play on Threejs
  /* playme(
     x = 0,
     y = 0,
     z = 0,
     degreeX = 0,
     degreeY = 0,
     degreeZ = 0,
     videoIndex = 0,
     position = new THREE.Vector3(-14, 0, 147)
   ) {
     const video = document.getElementById('video')
     // video.setAttribute('src', CHROMA_VIDEOS[videoIndex][this.langue])
     // Create your video texture:
     const videoTexture = new THREE.VideoTexture(video)
     videoTexture.format = THREE.RGBAFormat
     const videoMaterial = new THREE.MeshBasicMaterial({
       color: 'white',
       map: videoTexture,
       side: THREE.FrontSide,
       transparent: true
     })
 
     // Create screen using planeGeometry
     const screen = new THREE.PlaneGeometry(x, y)
     const videoScreen = new THREE.Mesh(screen, videoMaterial)
     videoScreen.renderOrder = 2
 
     // Make dynamic rotation
     videoScreen.rotateX(THREE.Math.degToRad(degreeX))
     videoScreen.rotateY(THREE.Math.degToRad(degreeY))
     videoScreen.rotateZ(THREE.Math.degToRad(degreeZ))
     videoScreen.position.copy(position)
     videoScreen.scale.multiplyScalar(55)
 
     return videoScreen
   } */

  /* stop(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 0,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'stop',
    popoverContent = 'stop'
  ) {
    const video = document.getElementById('stop')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    this.camera.position.set(200, 50, 400)
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    screen.name = name
    screen.popoverContent = name
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  } */

  /* play(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 0,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'playVideo',
    popoverContent = 'play'
  ) {
    const video = document.getElementById('play')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    this.camera.position.set(200, 50, 400)
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  } */

  /* quizz(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 2,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'clickedToQuizz',
    popoverContent = 'Quizz'
  ) {
    const video = document.getElementById('quizz')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  } */

  /* info(
    x = 0,
    y = 0,
    z = 0,
    tooltipNumber = 1,
    position = new THREE.Vector3(-14, 0, 147),
    name = 'info',
    popoverContent = 'info'
  ) {
    const video = document.getElementById('info')
    video.setAttribute('src', TOOLTIP[tooltipNumber].src)
    video.load()
    video.play()
    // Create your video texture:
    const Toltiptexture = new THREE.VideoTexture(video)
    const ToltipMaterial = new THREE.MeshBasicMaterial({ map: Toltiptexture, side: THREE.FrontSide, toneMapped: false, transparent: true })
    Toltiptexture.format = THREE.RGBAFormat
    // Create screen
    const screen = new THREE.PlaneGeometry(-1.5, 1.5, 1)
    const toltipScreen = new THREE.Mesh(screen, ToltipMaterial)
    toltipScreen.name = name
    toltipScreen.popoverContent = popoverContent
    toltipScreen.rotateX(THREE.Math.degToRad(x))
    toltipScreen.rotateY(THREE.Math.degToRad(y))
    toltipScreen.rotateZ(THREE.Math.degToRad(z))
    toltipScreen.renderOrder = 2
    toltipScreen.position.copy(position)
    return toltipScreen
  } */

  init() {
    // initiate camera position
    this.camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 1100)
    this.camera.target = new THREE.Vector3(0, 0, 0)
    this.camera.position.set(0, 0, 0)

    this.langue = localStorage.getItem('langue')

    // Creating scene 0 and adding button to interact with
    const scene0 = new THREE.Scene()
    scene0.name = 'EntryPart2'
    scene0.add(this.createSphere(this.EntryPart2))
    scene0.add(this.addSprite(new THREE.Vector3(-0.2, -0.4, -1.5), 'goToScene3', 'Entry', '/KSAMuseum/marqueur.png'))
    scene0.add(this.addSprite(new THREE.Vector3(-0.6, 0, 0.5), 'goToScene4', 'Water Well', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene0)

    // Creating scene 1 and adding button to interact with
    const scene1 = new THREE.Scene()
    scene1.name = 'AlMajless'
    scene1.add(this.createSphere(this.AlMajless))
    scene1.add(this.addSprite(new THREE.Vector3(0.1, 0, -0.7), 'goToScene3', 'Entry Part', '/KSAMuseum/marqueur.png'))
    scene1.add(this.addSprite(new THREE.Vector3(-0.8, -0.37, 0.7), 'goToScene2', 'Al Majless 2', '/KSAMuseum/marqueur.png'))

    this.scenes.push(scene1)

    // Creating scene 2 and adding button to interact with
    const scene2 = new THREE.Scene()
    scene2.name = 'AlMajless2'
    scene2.add(this.createSphere(this.AlMajless2))
    scene2.add(this.addSprite(new THREE.Vector3(1, 0.01, -1), 'goToScene1', 'Al Majless 1', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene2)

    // Creating scene 3 and adding button to interact with
    const scene3 = new THREE.Scene()
    scene3.name = 'EntryPart1'
    scene3.add(this.createSphere(this.EntryPart1))
    scene3.add(this.addSprite(new THREE.Vector3(0.15, -0.3, 0.6), 'goToScene0', 'Main Entry', '/KSAMuseum/marqueur.png'))
    scene3.add(this.addSprite(new THREE.Vector3(-0.8, -0.4, -1), 'goToScene1', 'Al Majless', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene3)

    // Creating scene 4 and adding button to interact with
    const scene4 = new THREE.Scene()
    scene4.name = 'HouseWell'
    scene4.add(this.createSphere(this.HouseWell))
    scene4.add(this.addSprite(new THREE.Vector3(0.9, 0, -1), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    scene4.add(this.addSprite(new THREE.Vector3(0.45, 0, 1), 'goToScene0', 'Main Entry', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene4)

    // Creating scene 5 and adding button to interact with
    const scene5 = new THREE.Scene(this.camera.position.set(14, 10, -50))
    scene5.name = 'InternalCourtyard'
    scene5.add(this.createSphere(this.InternalCourtyard))
    scene5.add(this.addSprite(new THREE.Vector3(-0.1, 0, -1), 'goToScene6', 'Al ahsaa', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(-0.92, -0.05, -0.36), 'goToScene7', 'Historic Documents', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(0.99, 0, 0), 'goToScene12', 'Kitchen', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(0.35, 0, 0.93), 'goToScene11', 'Women s Room', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(-0.52, -0.03, 0.85), 'goToScene8', 'Dates Storage', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(-0.21, 0.004, 0.97), 'goToScene9', 'Room Baiaa', '/KSAMuseum/marqueur.png'))
    scene5.add(this.addSprite(new THREE.Vector3(0.64, -0.04, -0.76), 'goToScene4', 'Water Well', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene5)

    // Creating scene 6 and adding button to interact with
    const scene6 = new THREE.Scene()
    scene6.name = 'AlAhsaa'
    scene6.add(this.createSphere(this.AlAhsaa))
    scene6.add(this.addSprite(new THREE.Vector3(0.96, -0.06, -0.23), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene6)

    // Creating scene 7 and adding button to interact with
    const scene7 = new THREE.Scene()
    scene7.name = 'HistoricDocuments'
    scene7.add(this.createSphere(this.HistoricDocuments))
    scene7.add(this.addSprite(new THREE.Vector3(-0.15, -0.07, -0.98), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene7)

    // Creating scene 8 and adding button to interact with
    const scene8 = new THREE.Scene()
    scene8.name = 'DatesStorage'
    scene8.add(this.createSphere(this.DatesStorage))
    scene8.add(this.addSprite(new THREE.Vector3(0.51, -0.01, -0.85), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene8)

    // Creating scene 9 and adding button to interact with
    const scene9 = new THREE.Scene()
    scene9.name = 'RoomBaiaa'
    scene9.add(this.createSphere(this.RoomBaiaa))
    scene9.add(this.addSprite(new THREE.Vector3(-0.66, 0.01, 0.74), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    scene9.add(this.addSprite(new THREE.Vector3(0.94, -0.19, -0.27), 'goToScene10', 'Bedroom (Dar)', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene9)

    // Creating scene 10 and adding button to interact with
    const scene10 = new THREE.Scene()
    scene10.name = 'BedroomAddar'
    scene10.add(this.createSphere(this.BedroomAddar))
    scene10.add(this.addSprite(new THREE.Vector3(0.33, -0.15, -0.92), 'goToScene9', 'Room Baiaa', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene10)

    // Creating scene 11 and adding button to interact with
    const scene11 = new THREE.Scene()
    scene11.name = 'WomensRoom'
    scene11.add(this.createSphere(this.WomensRoom))
    scene11.add(this.addSprite(new THREE.Vector3(-0.92, -0.07, -0.36), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene11)

    // Creating scene 12 and adding button to interact with
    const scene12 = new THREE.Scene()
    scene12.name = 'Kitchen'
    scene12.add(this.createSphere(this.Kitchen))
    scene12.add(this.addSprite(new THREE.Vector3(0.87, 0, 0.49), 'goToScene5', 'Internal Courtyard', '/KSAMuseum/marqueur.png'))
    this.scenes.push(scene12)

    // initialize the camera position into scene 0
    this.currentScene = scene0
    this.container.appendChild(this.renderer.domElement)
    // init controle to move around
    this.controls = new THREE.OrbitControls(this.camera, this.renderer.domElement)
    this.controls.rotateSpeed = -0.5
    this.controls.enableZoom = true
    this.controls.enablePan = false
    this.controls.maxDistance = 2
    this.camera.position.set(-80, 1, -40)
    this.controls.update()
    this.container.addEventListener('mousemove', this.onMouseMove, false)
    this.container.addEventListener('click', event => {
      const x = (event.clientX / window.innerWidth) * 2 - 1
      const y = -(event.clientY / window.innerHeight) * 2 + 1
      this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
      const intersects = this.raycaster.intersectObjects(this.currentScene.children)
      intersects.forEach(element => {
        console.log(element.object.name)
      })

      const intersectsObjects = intersects.filter(i => i.object.type === 'Sprite' || i.object.type === 'area' || i.object.type === 'Mesh')
      if (intersectsObjects) {
        intersectsObjects.map(io => {
          console.log('clicked' + io.object.name)
          if (io.object.name === 'nextScene') {
            this.goToNextScene()
          } else if (io.object.name === 'prevScene') {
            this.goToPrevScene()
          } else if (io.object.name.slice(0, -1) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-1))
          } else if (io.object.name.slice(0, -2) === 'goToScene') {
            this.goToScene(+io.object.name.slice(-2))
          } else if (io.object.name.slice(0, -1) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 1),
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToPlay') {
            this.EventBus.$emit('clickedToPlay', {
              playIndex: +io.object.name.substr(io.object.name.length - 2),
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -1) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-1))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name.slice(0, -2) === 'clickedToQuizz') {
            this.clickedToQuizz(+io.object.name.slice(-2))
            this.EventBus.$emit('clickedToQuizz', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name === 'clickedToGoPage') {
            this.EventBus.$emit('clickedToGoPage', {
              url: '/voting',
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name === 'playVideo') {
            this.EventBus.$emit('playVideo', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          } else if (io.object.name === 'stop') {
            this.EventBus.$emit('stop', {
              io,
              mouseCoordinates: {
                x: event.clientX,
                y: event.clientY
              }
            })
          }
        })
      }
    })

    this.animate()
  }

  animate = () => {
    requestAnimationFrame(this.animate)
    this.update()
  }

  update = () => {
    this.renderer.render(this.currentScene, this.camera)
  }

  // making the sphere Responsive
  onResize() {
    this.renderer.setSize(window.innerWidth, window.innerHeight)
    this.camera.aspect = window.innerWidth / window.innerHeight
    this.camera.updateProjectionMatrix()
  }

  onMouseMove = event => {
    /* const mouse = new THREE.Vector2(
      (event.clientX / window.innerWidth) * 2 - 1,
      -(event.clientY / window.innerHeight) * 2 + 1
    ) */
    const x = (event.clientX / window.innerWidth) * 2 - 1
    const y = -(event.clientY / window.innerHeight) * 2 + 1
    this.raycaster.setFromCamera(new THREE.Vector2(x, y), this.camera)
    const intersects = this.raycaster.intersectObjects(this.currentScene.children)
    const intersectsSprite = intersects.find(i => i.object.type === 'Sprite' || i.object.type === 'Mesh')

    if (intersectsSprite && !this.emitSpriteHoverIn && intersectsSprite.object.name !== '' && intersectsSprite.object.name !== 'environmentSphere') {
      const p = intersectsSprite.object.position.clone().project(this.camera)
      this.EventBus.$emit('i-got-hover-in', {
        sprite: intersectsSprite,
        mouseCoordinates: {
          y: ((-1 * p.y + 1) * window.innerHeight) / 2,
          x: ((p.x + 1) * window.innerWidth) / 2
        },
        popoverContent: intersectsSprite.object.popoverContent
      })
      this.emitSpriteHoverIn = true
      this.emitSpriteHoverOut = false
    } else if ((!intersectsSprite && !this.emitSpriteHoverOut) || (intersectsSprite && (intersectsSprite.object.name === '' || intersectsSprite.object.name === 'environmentSphere'))) {
      this.EventBus.$emit('i-got-hover-out')
      this.emitSpriteHoverOut = true
      this.emitSpriteHoverIn = false
    }
  }

  addPoint(sceneIndex = 0) {
    const map = new THREE.TextureLoader().load('https://images-na.ssl-images-amazon.com/images/I/41Y4fyn7HAL.png')
    const arrowMaterial = new THREE.SpriteMaterial({
      map: map
    })
    arrowMaterial.transparent = true
    const point = new THREE.Vector3(14, 1.9, -47)
    const sprite = new THREE.Sprite(arrowMaterial)
    sprite.name = 'enter'
    // On place le point un peu plus proche de la caméra pour un léger effet de parallaxe
    sprite.position.copy(
      point
        .clone()
        .normalize()
        .multiplyScalar(20)
    )
    this.scenes[sceneIndex].add(sprite)
    this.points.push(point)
  }

  destroy() {
    this.camera = null
    this.currentScene = null
    this.scenes = []
    this.renderer = null
    this.points = []
    this.container.removeEventListener('mousemove', this.onMouseMove, false)
  }

  EventBus = new Vue()
}
