import Vue from 'vue'
import VueCompositionApi from '@vue/composition-api'
import VueVideoPlayer from 'vue-video-player'
import { v4 as uuid } from 'uuid'

import AppLayout from './layout/index.vue'
import router from './router'
import store from './store'

import './mixins'
import './plugins'

import 'animate.css/animate.css'
import './assets/fonts/cairo/cairo.css'
import './scss/style.scss'
import './assets/styles/fonts/font-awesome.css'
import './assets/styles/fonts/feather-icons.css'

import {
  Button,
  Card,
  Modal,
  Layout,
  Menu,
  Breadcrumb,
  Icon,
  Row,
  Col,
  PageHeader,
  Statistic,
  Tag,
  List,
  Steps,
  Result,
  Form,
  FormModel,
  Input,
  InputNumber,
  Select,
  Radio,
  Spin,
  Avatar,
  message
} from 'ant-design-vue'

Vue.component(Layout.name, Layout)
Vue.component(Modal.name, Modal)
Vue.component(Icon.name, Icon)
Vue.component(Button.name, Button)
Vue.component(Card.name, Card)
Vue.component(Row.name, Row)
Vue.component(Col.name, Col)
Vue.component(List.name, List)
Vue.component(List.Item.name, List.Item)
Vue.component(List.Item.Meta.name, List.Item.Meta)
Vue.component(Spin.name, Spin)
Vue.component(Steps.name, Steps)
Vue.component(Steps.Step.name, Steps.Step)
Vue.component(Result.name, Result)
Vue.component(Form.name, Form)
Vue.component(FormModel.name, FormModel)
Vue.component(Form.Item.name, Form.Item)
Vue.component(Input.name, Input)
Vue.component(InputNumber.name, InputNumber)
Vue.component(Select.name, Select)
Vue.component(Select.Option.name, Select.Option)
Vue.component(Radio.name, Radio)
Vue.component(Radio.Group.name, Radio.Group)
Vue.component(Avatar.name, Avatar)
Vue.component(PageHeader.name, PageHeader)
Vue.component(Tag.name, Tag)
Vue.component(Statistic.name, Statistic)
Vue.component(Layout.Header.name, Layout.Header)
Vue.component(Layout.Sider.name, Layout.Sider)
Vue.component(Layout.Content.name, Layout.Content)
Vue.component(Layout.Footer.name, Layout.Footer)
Vue.component(Menu.name, Menu)
Vue.component(Menu.Item.name, Menu.Item)
Vue.component(Menu.SubMenu.name, Menu.SubMenu)
Vue.component(Breadcrumb.name, Breadcrumb)
Vue.component(Breadcrumb.Item.name, Breadcrumb.Item)

Vue.config.productionTip = false
Vue.prototype.$message = message
Vue.use(FormModel)
Vue.use(VueCompositionApi)

Vue.use(VueVideoPlayer)

new Vue({
  name: 'Root',
  router,
  store,
  mounted() {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
    if (localStorage.getItem('userID') === null) {
      localStorage.setItem('userID', uuid())
    }
    if (localStorage.getItem('version') === null) {
      localStorage.setItem('version', 'Audio-Visual')
    }
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
  },
  render: h => h(AppLayout)
}).$mount('#app')
