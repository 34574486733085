export const API_PORT = '5000'
export const API_URL = `https://localhost:44309/`
export const DOMAIN_TITLE = 'Virtual Reality Platform For Heritage Sites'

// Gouvernorats
export const GOUVS = [
    { name: 'Tunisie' },
    { name: 'Afghanistan' },
    { name: 'Afrique du Sud' },
    { name: 'Albanie' },
    { name: 'Algérie' },
    { name: 'Allemagne' },
    { name: 'Andorre' },
    { name: 'Angola' },
    { name: 'Anguilla' },
    { name: 'Arabie Saoudite' },
    { name: 'Argentine' },
    { name: 'Arménie' },
    { name: 'Australie' },
    { name: 'Autriche' },
    { name: 'Azerbaidjan' },
    { name: 'Bahamas' },
    { name: 'Bangladesh' },
    { name: 'Barbade' },
    { name: 'Bahrein' },
    { name: 'Belgique' },
    { name: 'Bélize' },
    { name: 'Bénin' },
    { name: 'Biélorussie' },
    { name: 'Bolivie' },
    { name: 'Botswana' },
    { name: 'Bhoutan' },
    { name: 'Boznie-Herzégovine' },
    { name: 'Brésil' },
    { name: 'Brunei' },
    { name: 'Bulgarie' },
    { name: 'Burkina Faso' },
    { name: 'Burundi' },
    { name: 'Cambodge' },
    { name: 'Cameroun' },
    { name: 'Canada' },
    { name: 'Cap-Vert' },
    { name: 'Chili' },
    { name: 'Chine' },
    { name: 'Chypre' },
    { name: 'Colombie' },
    { name: 'Comores' },
    { name: 'République du Congo' },
    { name: 'République Démocratique du Congo' },
    { name: 'Cook' },
    { name: 'Corée du Nord' },
    { name: 'Corée du Sud' },
    { name: 'Costa Rica' },
    { name: 'Côte d’Ivoire' },
    { name: 'Croatie' },
    { name: 'Cuba' },
    { name: 'Danemark' },
    { name: 'Djibouti' },
    { name: 'Dominique' },
    { name: 'Egypte' },
    { name: 'Emirats Arabes Unis' },
    { name: 'Equateur' },
    { name: 'Erythrée' },
    { name: 'Espagne' },
    { name: 'Estonie' },
    { name: 'Etats-Unis d’Amérique' },
    { name: 'Ethiopie' },
    { name: 'Fidji' },
    { name: 'Finlande' },
    { name: 'France' },
    { name: 'Gabon' },
    { name: 'Gambie' },
    { name: 'Géorgie' },
    { name: 'Ghana' },
    { name: 'Grèce' },
    { name: 'Grenade' },
    { name: 'Guatémala' },
    { name: 'Guinée' },
    { name: 'Guinée Bissau' },
    { name: 'Guinée Equatoriale' },
    { name: 'Guyana' },
    { name: 'Haïti' },
    { name: 'Honduras' },
    { name: 'Hongrie' },
    { name: 'Inde' },
    { name: 'Indonésie' },
    { name: 'Iran' },
    { name: 'Iraq' },
    { name: 'Irlande' },
    { name: 'Islande' },
    { name: 'Israël' },
    { name: 'italie' },
    { name: 'Jamaïque' },
    { name: 'Japon' },
    { name: 'Jordanie' },
    { name: 'Kazakhstan' },
    { name: 'Kenya' },
    { name: 'Kirghizistan' },
    { name: 'Kiribati' },
    { name: 'Koweït' },
    { name: 'Laos' },
    { name: 'Lesotho' },
    { name: 'Lettonie' },
    { name: 'Liban' },
    { name: 'Liberia' },
    { name: 'Liechtenstein' },
    { name: 'Lituanie' },
    { name: 'Luxembourg' },
    { name: 'Lybie' },
    { name: 'Macédoine' },
    { name: 'Madagascar' },
    { name: 'Malaisie' },
    { name: 'Malawi' },
    { name: 'Maldives' },
    { name: 'Mali' },
    { name: 'Malte' },
    { name: 'Maroc' },
    { name: 'Marshall' },
    { name: 'Maurice' },
    { name: 'Mauritanie' },
    { name: 'Mexique' },
    { name: 'Micronésie' },
    { name: 'Moldavie' },
    { name: 'Monaco' },
    { name: 'Mongolie' },
    { name: 'Mozambique' },
    { name: 'Namibie' },
    { name: 'Nauru' },
    { name: 'Nepal' },
    { name: 'Nicaragua' },
    { name: 'Niger' },
    { name: 'Nigéria' },
    { name: 'Nioué' },
    { name: 'Norvège' },
    { name: 'Nouvelle Zélande' },
    { name: 'Oman' },
    { name: 'Ouganda' },
    { name: 'Ouzbékistan' },
    { name: 'Pakistan' },
    { name: 'Palau' },
    { name: 'Palestine' },
    { name: 'Panama' },
    { name: 'Papouasie Nouvelle Guinée' },
    { name: 'Paraguay' },
    { name: 'Pays-Bas' },
    { name: 'Pérou' },
    { name: 'Philippines' },
    { name: 'Pologne' },
    { name: 'Portugal' },
    { name: 'Qatar' },
    { name: 'République centrafricaine' },
    { name: 'République Dominicaine' },
    { name: 'République Tchèque' },
    { name: 'Roumanie' },
    { name: 'Royaume Uni' },
    { name: 'Russie' },
    { name: 'Rwanda' },
    { name: 'Saint-Christophe-et-Niévès' },
    { name: 'Sainte-Lucie' },
    { name: 'Saint-Marin' },
    { name: 'Saint-Vincent-et-les Grenadines' },
    { name: 'Iles Salomon' },
    { name: 'Salvador' },
    { name: 'Samoa Occidentales' },
    { name: 'Sao Tomé et Principe' },
    { name: 'Sénégal' },
    { name: 'Serbie' },
    { name: 'Seychelles' },
    { name: 'Sierra Léone' },
    { name: 'Singapour' },
    { name: 'Slovaquie' },
    { name: 'Slovénie' },
    { name: 'Somalie' },
    { name: 'Soudan' },
    { name: 'Sri Lanka' },
    { name: 'Suède' },
    { name: 'Suisse' },
    { name: 'Suriname' },
    { name: 'Swaziland' },
    { name: 'Syrie' },
    { name: 'Tadjikistan' },
    { name: 'Taiwan' },
    { name: 'Tanzanie' },
    { name: 'Tchad' },
    { name: 'Thailande' },
    { name: 'Timor Oriental' },
    { name: 'Togo' },
    { name: 'Tonga' },
    { name: 'Trinité et Tobago' },
    { name: 'Turkménistan' },
    { name: 'Turquie' },
    { name: 'Tuvalu' },
    { name: 'Ukraine' },
    { name: 'Uruguay' },
    { name: 'Vanuatu' },
    { name: 'Vatican' },
    { name: 'Vénézuela' },
    { name: 'Vietnam' },
    { name: 'Yemen' },
    { name: 'Zambie' },
    { name: 'Zimbabwe' },
]

// modalContent
export const MODAL_CONENT_1 = {
    audio: '/KSAMuseum/audio/el_Jem.mp3',
    modalH4: 'Boutique MOBILITY',
    modalContent: `TotalEnergies a lancé un nouveau concept MOBILITY pour ses boutiques dans les stations services et au sein de son siège`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/KSAMuseum/monuments/11.png',
            backgroundImage: '/KSAMuseum/monuments/11.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn'
        },
        {
            id: 'MvR30qxn-MM',
            thumbUrl: '/KSAMuseum/monuments/22.png',
            backgroundImage: '/KSAMuseum/monuments/22.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn'

        }
    ]
}
export const MODAL_CONENT_2 = {
    modalH4: 'EQUIPE CARTES :',
    modalContent: `Les équipes front office et Back office sont à la disposition des clients  Cartes Carburants`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/KSAMuseum/monuments/22.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/KSAMuseum/monuments/22.png'
        }
    ]

}
export const MODAL_CONENT_3 = {
    modalH4: 'Hall d’entrée Rez de chaussée',
    modalContent: ``,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total6.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Total6.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total6.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Total6.png'
        }
    ]
}
export const MODAL_CONENT_4 = {
    modalH4: 'Espace détente :',
    modalContent: `Une bibliothéque de livres , un ensemle de jeux de société, un fauteuil relaxant
     et un espace détente dédié au personnel de TotalEnergies.
    `,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Love.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Love.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Love.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Love.png'
        }
    ]
}
export const MODAL_CONENT_5 = {
    modalH4: "Better together",
    modalContent: `L’équipe Environnement de travail et communication interne veille au bien être du personnel,
    organisation de team building, déjeuners, et différents évenements.`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/photo20.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/photo20.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/photo20.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/photo20.png'
        }
    ]
}
export const MODAL_CONENT_6 = {
    modalH4: "Equipe finances",
    modalContent: ` Le rôle des équipes de la Finance et de la Comptabilité est de définir et de mener les procédures nécessaires à l’application de la politique financière de la Compagnie.`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/transition.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/transition.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/transition.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/transition.png'
        }
    ]
}
export const MODAL_CONENT_7 = {
    modalH4: 'Equipe commerciale:',
    modalContent: `Concevoir et commercialiser dans les meilleures conditions les produits et les marques TotalEnergies,
     telles sont les principales missions de l’activité Commerce au sein de la Compagnie.`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/stationservice.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/stationservice.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/stationservice.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/stationservice.png'
        }
    ]
}
export const MODAL_CONENT_8 = {
    modalH4: 'Equipe Marketing & communication',
    modalContent: `Les équipes de la Communication sont chargées d’élaborer et de porter l’image de la Compagnie,
     dans l’objectif de contribuer à sa notoriété, facteur essentiel de son succès et reflet de ses performances dans le monde…`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/caf.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/caf.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/caf.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/caf.png'
        }
    ]
}
export const MODAL_CONENT_9 = {
    modalH4: 'Equipe Supply & logistique',
    modalContent: `La mission des équipes de logistique vise à optimiser l’ensemble de la chaîne d’approvisionnement
     et à réaliser les études qui permettront d’en améliorer les modalités et la sécurité`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total9.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Total9.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total9.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/Total9.png'
        }
    ]
}
export const MODAL_CONENT_10 = {
    modalH4: 'Equipe Transport',
    modalContent: `les logisticiens gèrent les flux des marchandises, par les moyens de transport les plus variés,
     en fonction de la nature du produit, de son lieu d’origine et de sa destination.<br />
      Ils font appel aux nouvelles technologies, comme par exemple pour l’optimisation des tournées d’approvisionnement des stations-service`,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/image1.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/image1.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/image4.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/image4.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/image3.png',
            title: `${3}/3`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/image3.png'
        }
    ]
}
export const MODAL_CONENT_11 = {
    modalH4: 'Espace de détente du 4e étage',
    modalContent: ``,
    slides: [
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/presencepays.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/presencepays.png'
        },
        {
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/presencepays.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn',
            backgroundImage: '/Total/monuments/presencepays.png'
        }
    ]
}
export const MODAL_CONENT_12 = {
    modalH4: 'Terrasse du 4e étage:',
    modalContent: `espace aménagé mis à la disposition du personnel de TotalEnergies`,
    slides: [
        {
            backgroundImage: '/Total/monuments/Total12.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total12.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn'
        },
        {
            backgroundImage: '/Total/monuments/Total12.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total12.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const MODAL_CONENT_13 = {
    modalH4: 'Equipe Hygiène Sécurité Environnement et Qualité',
    modalContent: `La fonction HSEQ, transverse à l’ensemble des activités, 
    est intimement liée et intégrée à la stratégie globale de la Compagnie dans le monde, 
    aussi bien en interne qu’en externe. L’équipe HSE élabore les plans et les règles et veillent à leur application,
     tout en développant un esprit de prévention nécessaire à des conditions de travail optimales.`,
    slides: [
        {
            backgroundImage: '/Total/monuments/image2.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/image2.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn'
        },
        {
            backgroundImage: '/Total/monuments/image2.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/image2.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const MODAL_CONENT_14 = {
    modalH4: 'Equipe réseau et commerciale',
    modalContent: `Gestion et optimisation des performances économiques et 
    commerciales d’un réseau de stations-services dans le respect des normes de qualité,
     sécurité, environnement et de l’image de marque`,
    slides: [
        {
            backgroundImage: '/Total/monuments/stationservice.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/stationservice.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn'
        },
        {
            backgroundImage: '/Total/monuments/stationservice.png',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/stationservice.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const MODAL_CONENT_15 = {
    modalH4: 'Coworking Space',
    modalContent: `espace dédié au personnel pour travailler en parfaite synergie et communauté.`,
    slides: [
        {
            backgroundImage: '/Total/monuments/Total14.PNG',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total14.png',
            title: `${1}/2`,
            animatation: 'animate__fadeIn'
        },
        {
            backgroundImage: '/Total/monuments/Total14.PNG',
            id: 'k-Cf0kRruew',
            thumbUrl: '/Total/monuments/Total14.png',
            title: `${2}/2`,
            animatation: 'animate__fadeIn'
        }
    ]
}
export const Camera_Positions = [{
    index: 0,
    position: { x: -20, y: 0, z: 0 }
},
{
    index: 1,
    position: { x: -50, y: 10, z: 8 }
},
{
    index: 2,
    position: { x: -30, y: 0, z: -10 }
},
{
    index: 3,
    position: { x: -10, y: 0, z: 20 }
},
{
    index: 4,
    position: { x: 20, y: 5, z: 25 }
},
{
    index: 5,
    position: { x: -600, y: 8, z: 80 }
},
{
    index: 6,
    position: { x: 80, y: 0, z: 25 }
},
{
    index: 7,
    position: { x: -40, y: 26, z: 60 }
},
{
    index: 8,
    position: { x: 0, y: 0, z: 1 }
},
{
    index: 9,
    position: { x: -300, y: 0, z: 55 }
},
{
    index: 10,
    position: { x: -90, y: 40, z: 150 }
},
{
    index: 11,
    position: { x: 0, y: 30, z: -100 }
},
{
    index: 12,
    position: { x: -20, y: 6, z: 15 }
},
{
    index: 13,
    position: { x: 10, y: 0, z: 15 }
},
{
    index: 14,
    position: { x: -50, y: 10, z: 30 }
}
]
export const TOOLTIP = [
    {
        index: 0,
        title: 'video',
        src: '/videos/button/PLAY2.webm'
    },
    {
        index: 1,
        title: 'info',
        src: '/videos/button/INFO2.webm'
    },
    {
        index: 2,
        title: 'quizz',
        src: '/videos/button/QUIZ2.webm'
    },
    {
        index: 3,
        title: 'stop',
        src: '/videos/button/STOP.webm'
    }

]
export const INTER_VIDEOS = [
    {
        title: 'IMEN',
        srcfr:
        '/videos/interactive/1_choix1_president.mp4',
      }
]
export const Scene_Names = [{
    index: 0,
    Scene_Name: 'RdcWelcome'
},
{
    index: 1,
    Scene_Name: 'Rdcoffice1'},
{
    index: 2,
    Scene_Name: 'Rdcoffice2'},
{
    index: 3,
    Scene_Name: 'Rdcoffice3'},
{
    index: 4,
    Scene_Name: 'Rdcoffice4'},
{
    index: 5,
    Scene_Name: 'FirstWelcome'},
{
    index: 6,
    Scene_Name: 'ThirdWelcome'},
{
    index: 7,
    Scene_Name: ' Thirdoffice1'},
{
    index: 8,
    Scene_Name: 'FourthWelcome'},
{
    index: 9,
    Scene_Name: 'Fourthoffice1'},
{
    index: 10,
    Scene_Name: 'Fourthoffice2'},
{
    index: 11,
    Scene_Name: 'Fourthoffice3'},
{
    index: 12,
    Scene_Name: 'Fourthoffice4'},
{
    index: 13,
    Scene_Name: 'FourtOutside'},
{
    index: 14,
    Scene_Name: 'FifthRdcWelcome'}
]
