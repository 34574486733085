<template>
  <div>
    <loader v-if="!isLoaded"></loader>
    <div ref="sceneContainer" style="width:100vw; height:100vh"></div>
    <div
      class="popover fade bs-popover-top"
      :class="popover.show ? 'show' : ''"
      role="tooltip"
      id="popover246408"
      x-placement="top"
      :style="
        `pointer-events:none;
         background: linear-gradient(34deg, rgba(110,110,110,0.6278886554621849) 19%, rgba(255,255,255,0.7315301120448179) 78%);
         min-width: 8rem;
         line-height: 1.5rem;
         font-size: 1.1rem;
         position: absolute;
         will-change: transform;
         top: 0px;
         left: 0px;
         transform: translate3d(${popover.position.x - 90}px, ${popover.position.y - 50}px, 0px);`
      "
    >
      <div class="arrow"></div>
      <div class="popover-body text-center" style="color:white">{{ popover.popoverContent }}</div>
    </div>
    <a-modal
      v-model="visibleModalIntVideos"
      :footer="null"
      :closable="false"
      :body-style="{ padding: 0 }"
      :afterClose="() => $refs.videoPlayerInterVideos.player.pause()"
      style="top: 70px;"
      width = "400px"
      height = "600px"
    >
      <div class="some-modal-content">
        <video-player
          width = "400px"
          height = "600px"
          class="video-player-box"
          ref="videoPlayerInterVideos"
          style="height: 600px; width: 400px"
          :playsinline="true"
          @ended="onPlayerInterVideosEnded($event)"
        ></video-player>
        <div class="buttons text-center text-white">
          <a-button
            class="text-white"
            @click="visibleModalIntVideos = false"
            shape="circle"
            icon="close"
          />
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="visibleModalPlay"
      :footer="null"
      :closable="false"
      :maskClosable="false"
      :afterClose="() => $refs.videoPlayer.player.pause()"
      :body-style="{ padding: 0 }"
    >
      <div class="some-modal-content">
        <awesome-slider
          v-if="version !== 'Visual'"
          :slides="slides"
          :height="'250px'"
          :parallax="false"
          :content="[]"
        />
        <video-player
          v-show="version === 'Visual'"
          class="video-player-box"
          style="height: 250px"
          ref="videoPlayer"
          :playsinline="true"
          customEventName="customstatechangedeventname"
        ></video-player>
        <div class="bg-white p-3 text-dark">
          <div class="mb-3 mt-1 d-flex bd-highlight">
            <div class="flex-grow-1 bd-highlight">
              <h4 class="mb-0 mt-2" v-html="modalH4"></h4>
            </div>
            <div class="bd-highlight">
              <a-button
                v-if="version !== 'Visual'"
                @click="playAndPause"
                type="primary"
                shape="circle"
                :icon="playPauseIcon"
                size="large"
              />
            </div>
          </div>

          <p class="text-right" v-html="modalContent"></p>
        </div>
        <div class="buttons text-center text-white">
          <a-button class="text-white" @click="closeModalPlay" shape="circle" icon="close" />
        </div>
      </div>
    </a-modal>
    <a-modal
      v-model="visibleModalQuizz"
      :closable="false"
      :maskClosable="false"
      :afterClose="
        () => {
          $refs.quizzFormRef.resetFields()
          quizzCurrent = 0
          finishQuizzForm = false
        }
      "
      :footer="null"
      :body-style="{ padding: 0 }"
      width="600px"
      style="top: 70px;"
    >
      <div class="some-modal-content form-quizz">
        <div class="bg-white">
          <div class="py-3 px-2 clearfix">
            <h2 class="border-bottom mb-4 pb-3 text-right">مسابقة</h2>
            <div v-show="!finishQuizzForm" class="px-3">
              <a-form-model
                ref="quizzFormRef"
                :model="formQuizz"
                :label-col="labelCol"
                :wrapper-col="wrapperCol"
                :rules="formQuizzRules"
                :labelAlign="'right'"
                :layout="'vertical'"
              >
                <a-form-model-item
                  label="الجنس"
                  :labelAlign="'right'"
                  class="sex-form-control"
                  prop="gender"
                >
                  <a-radio-group v-model="formQuizz.gender">
                    <a-radio value="homme">رجل</a-radio>
                    <a-radio value="femme">امرأة</a-radio>
                  </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="السن" prop="age">
                  <a-input-number v-model="formQuizz.age" />
                </a-form-model-item>
                <a-form-model-item label="المنطقة" prop="region">
                  <a-select v-model="formQuizz.region" placeholder="please select your region">
                    <a-select-option
                      v-for="gouv in gouvs"
                      :key="gouv.name"
                      :title="gouv.name"
                    >{{ gouv.name }}</a-select-option>
                  </a-select>
                </a-form-model-item>
                <a-form-model-item :wrapper-col="{ span: 8, offset: 10 }">
                  <a-button type="primary" @click="onSubmit">إستمر</a-button>
                  <a-button
                    style="margin-left: 10px;"
                    @click="$refs.quizzFormRef.resetFields()"
                  >إلغاء</a-button>
                </a-form-model-item>
              </a-form-model>
            </div>
            <div
              v-if="finishQuizzForm && quizzCurrent < quizzSteps.length && quizzSteps.length > 0"
            >
              <a-steps :current="quizzCurrent" size="small">
                <a-step v-for="(item, quizzIndex) in quizzSteps" :key="quizzIndex" :title="''" />
              </a-steps>
              <div class="steps-content mt-4">
                <div v-if="quizzCurrent < quizzSteps.length">
                  <h3 dir= "rtl" class="mb-4 text-center" v-html="quizzSteps[quizzCurrent].questionText"></h3>
                  <div class="px-3">
                    <a-button
                      dir= "rtl"
                      v-for="(answer, answerIndex) in quizzSteps[quizzCurrent].answers"
                      :key="answerIndex"
                      size="large"
                      @click="nextQuizz($event, answerIndex, quizzCurrent)"
                      block
                      class="mb-2 animate__animated"
                    >{{ answerIndex+1 }} - {{ answer.answerText }}</a-button>
                  </div>
                  <h3 v-show="version === 'Visual'">
                    <video-player
                  v-show="version === 'Visual'"
                  class="video-player-box bg-transparent"
                  style="height: 250px; background-color: white !important;"
                  ref="videoPlayerQuiz"
                  :options=" {
        // videojs options
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        controls: false,
        loop: false,
        muted: true,
        autoplay: true,
        sources: [
          {
            type: 'video/webm',
            src: quizzSteps[quizzCurrent].videoFilePath
          }
        ]
      }"
                  :playsinline="true"
                ></video-player>
                </h3>
                </div>
              </div>
            </div>
            <div class="steps-action text-center" v-if="quizzCurrent == quizzSteps.length">
              <a-result title="رائع ، لقد قمنا بكل الأسئلة">
                <template #icon>
                  <a-icon type="smile" theme="twoTone" />
                </template>
                <template #extra>
                  <a-button type="primary" @click="visibleModalQuizz = false">خروج</a-button>
                </template>
              </a-result>
            </div>
          </div>
        </div>
        <div class="buttons text-center text-white">
          <a-button
            class="text-white"
            @click="visibleModalQuizz = false"
            shape="circle"
            icon="close"
          />
        </div>
      </div>
    </a-modal>

  </div>
</template>

<script>
import { Howl } from 'howler'

import {
  MODAL_CONENT_1,
  MODAL_CONENT_2,
  MODAL_CONENT_3,
  MODAL_CONENT_4,
  MODAL_CONENT_5,
  MODAL_CONENT_6,
  MODAL_CONENT_7,
  MODAL_CONENT_8,
  MODAL_CONENT_9,
  MODAL_CONENT_10,
  MODAL_CONENT_11,
  /* API_URL, */
  Scene_Names,
  INTER_VIDEOS,
  GOUVS
} from '@/.env'
import Loader from '@/components/slider/Loader.vue'
import AwesomeSlider from '@/components/slider/AwesomeSlider.vue'
/* import { QuizzService } from '@/services/quizz.service'
import { ReportingService } from '@/services/reporting.service'
import { v4 as uuid } from 'uuid' */
import { Scene } from '../thirdParty/index.js'

export default {
  name: 'Mazars',
  components: {
    Loader,
    AwesomeSlider
  },

  el: '#app',
  data() {
    return {
      visibleModalIntVideos: false,
      gouvs: GOUVS,
      quiz: false,
      userServerId: 0,
      questionIndex: 0,
      isOpen: false,
      stat: false,
      finishQuizzForm: false,
      isModalVisible: false,
      showModal: false, // added by me
      isLoaded: false,
      version: 'Audio-Visual',
      loadingQuizz: true,
      visibleModalImage: false,
      visibleModalPlay: false,
      visibleModalvideo: false,
      visibleModalQuizz: false,
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      freeResponse: '',
      formQuizz: {
        FirstName: '',
        LastName: '',
        region: undefined,
        age: '',
        gender: ''
      },
      formQuizzRules: {
        FirstName: [{ required: false, message: "S'il vous plaît écrivez ton nom", trigger: 'blur' }],
        LastName: [{ required: false, message: "S'il vous plaît écrivez ton prénom", trigger: 'blur' }],
        age: [
          { required: true, message: "S'il vous plaît écrivez l'âge", trigger: 'blur' },
          { type: 'number', message: "l'âge en chiffres", trigger: 'blur' }
        ],
        region: [{ required: false, message: 'Veuillez sélectionner votre pays', trigger: 'change' }],
        gender: [{ required: true, message: 'Veuillez sélectionner un sexe', trigger: 'change' }]
      },
      visibleModalInterVideosChoice: false,
      playerInterVideosOptions: {
        // videojs options
        language: 'en',
        playbackRates: [0.7, 1.0, 1.5, 2.0],
        sources: [
          {
            type: 'video/mp4',
            src: '/videos/interactive/2_into.webm'
          }
        ]
      },
      currentInterVideos: {},
      quizzCurrent: 0,
      quizzSteps: [],
      enquetteCurrent: 0,
      enquetteSteps: [],
      inPlay: false,
      player: null,
      playPauseIcon: 'caret-right',
      slides: [],
      imageMuseum: [],
      loading: false,
      ThreeScene: null,
      popover: {
        show: false,
        popoverContent: '',
        position: {
          x: 0,
          y: 0
        }
      },
      modalH4: 'modal title H4',
      modalContent: 'modal Content'
    }
  },
  computed: {
    myVideoPlayer() {
      return this.$refs.videoPlayer.player
    }
  },
  async mounted() {
    /* console.log(this.userServerId)
    if (localStorage.getItem('userID') === null) {
      localStorage.setItem('userID', uuid())
    }
    if (localStorage.getItem('langue') === null) {
      localStorage.setItem('langue', 'fr')
    }
    this.langue = localStorage.getItem('langue')
    if (localStorage.getItem('userServerId') === null) {
      this.userServerId = 0
    } else {
      this.userServerId = localStorage.getItem('userServerId')
    } */
    /* const data = {
      type: 'page',
      detail: 'Mazars',
      version: this.langue,
      SessionId: localStorage.getItem('userID')
    } */
    /*  await ReportingService.sendReport(data) */
    // console.log(res)
    const imageIsLoaded = src => {
      const image = document.createElement('img')
      image.src = src
      return new Promise((resolve, reject) => {
        image.onload = () => {
          resolve(true)
        }
        image.onerror = error => {
          reject(error)
        }
      })
    }
    const container = this.$refs.sceneContainer
    const UrlTextureEntryPart1 = 'https://d2vm0afvtrg4mc.cloudfront.net/1.jpg'
    const UrlTextureAlMajless = 'https://d2vm0afvtrg4mc.cloudfront.net/2.jpg'
    const UrlTextureAlMajless2 = 'https://d2vm0afvtrg4mc.cloudfront.net/3.jpg'
    const UrlTextureEntryPart2 = 'https://d2vm0afvtrg4mc.cloudfront.net/4.jpg'
    const UrlTextureHouseWell = 'https://d2vm0afvtrg4mc.cloudfront.net/5.jpg'
    const UrlTextureInternalCourtyard = 'https://d2vm0afvtrg4mc.cloudfront.net/6.jpg'
    const UrlTextureAlAhsaa = 'https://d2vm0afvtrg4mc.cloudfront.net/7.jpg'
    const UrlTextureHistoricDocuments = 'https://d2vm0afvtrg4mc.cloudfront.net/8.jpg'
    const UrlTextureDatesStorage = 'https://d2vm0afvtrg4mc.cloudfront.net/9.jpg'
    const UrlTextureRoomBaiaa = 'https://d2vm0afvtrg4mc.cloudfront.net/10.jpg'
    const UrlTextureBedroomAddar = 'https://d2vm0afvtrg4mc.cloudfront.net/11.jpg'
    const UrlTextureWomensRoom = 'https://d2vm0afvtrg4mc.cloudfront.net/12.jpg'
    const UrlTextureKitchen = 'https://d2vm0afvtrg4mc.cloudfront.net/13.jpg'
    this.ThreeScene = new Scene(
      container,
      UrlTextureEntryPart1,
      UrlTextureAlMajless,
      UrlTextureAlMajless2,
      UrlTextureEntryPart2,
      UrlTextureHouseWell,
      UrlTextureInternalCourtyard,
      UrlTextureAlAhsaa,
      UrlTextureHistoricDocuments,
      UrlTextureDatesStorage,
      UrlTextureRoomBaiaa,
      UrlTextureBedroomAddar,
      UrlTextureWomensRoom,
      UrlTextureKitchen
    )
    this.ThreeScene.init()
    const loaded = []

    /* await imageIsLoaded(UrlTextureEntryPart1)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureAlMajless)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureAlMajless2)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureEntryPart2)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureHouseWell)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureInternalCourtyard)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureAlAhsaa)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureHistoricDocuments)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureDatesStorage)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureRoomBaiaa)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureBedroomAddar)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    await imageIsLoaded(UrlTextureWomensRoom)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      }) */
    await imageIsLoaded(UrlTextureKitchen)
      .then((result) => loaded.push(result))
      .catch((error) => {
        console.error(error)
      })
    this.isLoaded = !loaded.includes(r => false)
    this.ThreeScene.EventBus.$on('i-got-hover-in', obj => {
      this.popover.show = true
      this.popover.popoverContent = obj.popoverContent
      this.popover.position = obj.mouseCoordinates
    })
    this.ThreeScene.EventBus.$on('i-got-hover-out', () => {
      this.popover.show = false
    })
    this.ThreeScene.EventBus.$on('playVideo', () => {
      this.$refs.videoChroma.muted = false
      this.$refs.videoChroma.play()
      this.$refs.audio.muted = true
    })
    this.ThreeScene.EventBus.$on('clickedToPlay', o => {
      this.popover.show = false
      var station = ''
      const modalContent = index => {
        switch (index) {
          case 1:
            station = 'Mazars'
            return MODAL_CONENT_1
          case 2:
            station = 'Bienvenue au cabinet Mazars en Tunisie'
            return MODAL_CONENT_2
          case 3:
            station = 'Département Outsourcing'
            return MODAL_CONENT_3
          case 4:
            station = 'Mazars en Tunisie s’engage'
            return MODAL_CONENT_4
          case 5:
            station = 'Il ne s’agit jamais seulement du travail '
            return MODAL_CONENT_5
          case 6:
            station = 'Vous n’aurez jamais une seconde chance de démarrer votre carrière'
            return MODAL_CONENT_6
          case 7:
            station = 'Département Audit, IT et Tax'
            return MODAL_CONENT_7
          case 8:
            station = 'Le département Audit'
            return MODAL_CONENT_8
          case 9:
            station = 'Open space'
            return MODAL_CONENT_9
          case 10:
            station = 'Mazars'
            return MODAL_CONENT_10
          case 11:
            station = 'Département Conseil Financier et RH'
            return MODAL_CONENT_11
        }
        return modalContent
      }
      this.initPlayer(modalContent(o.playIndex).audio)
      this.modalH4 = modalContent(o.playIndex).modalH4
      this.modalContent = modalContent(o.playIndex).modalContent
      this.slides = modalContent(o.playIndex).slides
      this.visibleModalPlay = true
      this.sendStats('station', station)
    })
    this.ThreeScene.EventBus.$on('clickedToInterVideos', (o) => {
      this.popover.show = false
      this.currentInterVideos = INTER_VIDEOS[o.videoIndex]
      this.visibleModalIntVideos = true
      this.$nextTick().then(() => {
        this.$refs.videoPlayerInterVideos.player.src(INTER_VIDEOS[o.videoIndex].srcfr)
        this.$refs.videoPlayerInterVideos.player.play()
      })
      this.sendStats('interactive video', INTER_VIDEOS[o.videoIndex].title)
    })
    this.ThreeScene.EventBus.$on('clickedToGoPage', (o) => {
      this.$router.push(o.url)
    })
    this.ThreeScene.EventBus.$on('clickedToQuizz', o => {
      this.popover.show = false
      this.visibleModalQuizz = true
      document.getElementById('myAudio').muted = false
      console.log('ID', this.userServerId)
      this.quizLimit()
      this.sendStats('quiz', 'i n s c r i p t i o n')
    })
    this.ThreeScene.EventBus.$on('goToScene', o => {
      console.log('enter')
      var sceneIndex = localStorage.getItem('sceneIndex')
      var Scene_Name = Scene_Names[sceneIndex].Scene_Name
      console.log('Scene_Name', Scene_Name)
      this.popover.show = false
      this.sendStats('scene', Scene_Name)
    })
    this.ThreeScene.EventBus.$on('clickedToGoPage', o => {
      this.$router.push(o.url)
    })
  },
  /* created() {
    this.fetchData()
    this.quizLimit()
  }, */
  unmounted() {
    this.ThreeScene.destroy()
    this.ThreeScene = null
    this.player = null
  },
  beforeDestroy() {
    if (this.player) {
      this.player.stop()
      this.inPlay = false
    }
  },
  methods: {
    onSubmit() {
      this.$refs.quizzFormRef.validate(valid => {
        if (valid) {
          const req = {
            FirstName: this.formQuizz.FirstName,
            LastName: this.formQuizz.LastName,
            HommeFemme: this.formQuizz.gender,
            Region: this.formQuizz.region,
            Age: this.formQuizz.age + ''
          }
          // should removed console
          console.log(req)
          this.sendUserForm(req)
        } else {
          return false
        }
      })
    },
    quizLimit() {
      var QI = localStorage.getItem('questionIndex')
      this.questionIndex = JSON.parse(QI)
      this.quizzCurrent = JSON.parse(QI)
      return this.questionIndex
    },
    showModalx() {
      this.isModalVisible = true
    },
    closeModal() {
      this.isModalVisible = false
    },
    closeQuiz: function() {
      this.visibleModalQuizz = false
      console.log('stop sound')
    },
    closePopup: function() {
      this.isOpen = false
      this.showModal = false
    },
    showPopupp: function() {
      this.isOpen = true
      this.showModal = true
    },
    showPopup() {
      document.getElementById('myAudio').muted = false
    },
    closeModalPlay(e) {
      if (this.player) {
        this.inPlay && this.player.stop()
        this.inPlay = false
        this.playPauseIcon = 'caret-right'
      }
      this.visibleModalPlay = false
    },
    initPlayer(file) {
      const self = this
      this.player = new Howl({
        src: file,
        autoplay: false,
        loop: false,
        volume: 1,
        onend: function() {
          self.inPlay = false
          self.playPauseIcon = 'caret-right'
        }
      })
    },
    playAndPause() {
      if (this.player) {
        this.inPlay = !this.inPlay
        this.inPlay ? this.player.play() : this.player.pause()
        this.playPauseIcon = this.inPlay ? 'pause' : 'caret-right'
      }
    },
    /* async sendStats(typeS, detailS) {
      const data = {
        type: typeS,
        detail: detailS,
        version: localStorage.getItem('version'),
        SessionId: localStorage.getItem('userID')
      }
      await ReportingService.sendReport(data)
      // console.log(res)
    }, */
    /* async fetchData() {
      this.loadingQuizz = true
      try {
        const { data } = await QuizzService.getQuizz()
        this.quizzSteps = data.content.map(q => ({
          questionNumber: q.questionNumber,
          questionText: q.questionText,
          videoFilePath: API_URL + q.videoFilePath,
          myAnswer: false,
          answerId: 0,
          answers: q.answers.map(a => ({
            id: a.id,
            answerText: a.answerText,
            isCorrect: a.isCorrect,
            questionId: a.questionId
          }))
        }))
        var dataEnquette = await QuizzService.getEnquette()
        this.enquetteSteps = dataEnquette.data.content.map(q => ({
          questionNumber: q.questionNumber,
          questionText: q.questionText,
          isFreeAnswer: q.isFreeAnswer
        }))
      } catch (e) {
        console.log(e)
      } finally {
        this.loadingQuizz = false
      }
    }, */
    /* async sendMyAnswers(dataReq) {
      try {
        await QuizzService.sendMyAnswers(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    }, */
    /* async sendAnswer(dataReq) {
      try {
        await QuizzService.sendAnswer(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    }, */
    /* async sendEnquetteAnswer(dataReq) {
      try {
        await QuizzService.sendEnquetteAnswer(dataReq)
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
    }, */
    /* async sendUserForm(dataReq) {
      try {
        await QuizzService.sendUserForm(dataReq)
        this.userServerId = localStorage.getItem('userServerId')
        // console.log(data)
      } catch (e) {
        console.log(e)
      }
      this.userServerId = localStorage.getItem('userServerId')
    }, */
    nextQuizz(e, answerIndex, quizzIndex) {
      const parentChildNodes = [...e.path[1].childNodes]
      console.log('parentChildNodes', parentChildNodes)
      this.quizzSteps[quizzIndex].myAnswer = this.quizzSteps[quizzIndex].answers[answerIndex].isCorrect
      console.log('is correct', this.quizzSteps[quizzIndex].myAnswer)
      this.quizzSteps[quizzIndex].answerId = this.quizzSteps[quizzIndex].answers[answerIndex].id
      console.log('answerindex', this.quizzSteps[quizzIndex].answerId)
      parentChildNodes.map((b, index) => {
        const isCorrect = this.quizzSteps[quizzIndex].answers[index].isCorrect
        console.log('isCorrect', isCorrect)
        b.classList.add(isCorrect ? 'right-answer' : 'wrong-answer', isCorrect ? 'animate__shakeX' : 'animate__flash')
        b.disabled = false
      })
      setTimeout(() => {
        parentChildNodes.map((b, index) => {
          const isCorrect = this.quizzSteps[quizzIndex].answers[index].isCorrect
          b.classList.remove(
            isCorrect ? 'right-answer' : 'wrong-answer',
            isCorrect ? 'animate__shakeX' : 'animate__flash'
          )
          b.disabled = false
        })
        ++this.quizzCurrent
        console.log('Current Quiz Index : ' + this.quizzCurrent)
        console.log('Current Question Set Index : ' + this.questionIndex)
      }, 1500)
      const req = {
        Id: this.quizzSteps[quizzIndex].answerId,
        QuestionId: this.quizzSteps[quizzIndex].questionNumber,
        IsCorrect: this.quizzSteps[quizzIndex].myAnswer,
        UserId: localStorage.getItem('userServerId')
      }
      // should removed console
      console.log(req)
      this.sendAnswer(req)
      // this.$message.success('Processing complete!')
    },

    /* nextEnquette(e, reponse, EnqueteIndex) {
      if (reponse === 'Free') {
        reponse = this.freeResponse
      }
      const req = {
        QuestionId: this.enquetteSteps[EnqueteIndex].questionNumber,
        Answer: reponse
      }
      ++this.enquetteCurrent
      this.sendEnquetteAnswer(req)
      // this.$message.success('Processing complete!')
    }, */
    onPlayerInterVideosEnded(e) {
      this.visibleModalIntVideos = false
    },
    playChoice(video) {
      if (this.version === 'Visual') {
        this.$refs.videoPlayerInterVideos.player.src(video.srcSL)
      } else {
        this.$refs.videoPlayerInterVideos.player.src(video.src)
      }
      this.$refs.videoPlayerInterVideos.player.play()
      this.visibleModalInterVideosChoice = false
    },
    gotoSecond() {
      this.ThreeScene.goToScene(11)
    }
  }
}
</script>

<style lang="scss" scoped>
.some-modal-content {
  .buttons button {
    margin: 1rem;
    background-color: rgba(63, 63, 63, 0.123);
    :hover::before {
      transform: rotate(360deg);
    }
  }
}
.enqResp {
  padding-top: 85%;
  margin-left: -5%;
  color: #007aff;
  font-weight: 700;
  font-size: 12px;
}
.right-answer {
  color: white !important;
  background-color: rgb(0, 255, 21) !important;
}
.wrong-answer {
  color: white !important;
  background-color: red !important;
}
.statpopup {
  background-color: #fff;
  height: 50%;
}
.logo {
  margin-top: 2%;
  margin-right: 0;
}

.textarea {
  margin-left: 8%;
}
.bouttonEnv {
  background-color:#0071ce;
  color: white;
  width: 20%;
  margin-left: 40%;
}

.header {
  .navbar-dark .navbar-nav .nav-link {
    color: rgba(255, 255, 255, 0.95);
  }
  .router-link-exact-active.is-active {
    color: #ffffff;
    border-bottom: 1px solid;
  }
}
.navigation {
  width: 100%;
  position: fixed;
  bottom: 15px;
}
.lg {
  background-color: #fff;
  top: 5%;
  width: auto;
  height: 100px;
  position: fixed;
  border-radius: 0 10px 10px 0;
}
.answer {
  padding: 10px;
  border: 0.5px solid #0071ce;
  border-radius: 10px;
  color: #0071ce;
}
.answer:hover {
  background-color: #0071ce;
  color: white;
}
.h {
  color: #0071ce;
  font-family: 'Robot';
  font-weight: bold;
  text-align: center;
  margin-top: 10%;
}
.closebutton {
  border: 3px solid white;
  font-weight: bold;
  background-color: transparent;
  color: white;
  position: absolute;
  top: -6%;
  right: -7%;
}
.poquiz {
  margin-top: 1%;
}
.quizContent {
  height: 100%;
  margin-right: 30%;
  width: 900px;
  background: white;
  right: 50%;
  aspect-ratio: auto;
}
.compteur {
  height: 2%;
  padding: 4px 4px 4px 4px;
  width: auto;
  background-color: #0071ce;
  color: white;
  font-size: 15px;
  right: 0;
  border-radius: 5px 0px 0px 5px;
}
.plan-popup {
  width: 100%;
  height: 100%  ;
  img {
    width: 100%;
    height: 100%;
  }
}
.area {
  cursor: pointer;
}
.line {
  margin-left: 14%;
  margin-right: 14%;
  margin-bottom: 5%;
  border: 1px solid #0071ce;
}

.loading-bar {
  height: 10px;
  bottom: 0px;
  padding-bottom: 0%;
  // border-radius: 0px 0px 15px 15px;
  background: #19329a;
}

@media screen and (max-width: 1243px) {
  .plan-popup {
    width: 100%;
    height: 100%;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 25px;
  }
  .buttonc {
    background-size: 100%;
    margin-left: 40%;
    width: 20%;
    height: 60px;
  }
  .buttonl {
    background-size: 100%;
    margin-left: 19%;
    height: 60px;
    width: 20%;
  }
  .buttonq {
    background-size: 100%;
    margin-left: 61%;
    height: 60px;
    width: 20%;
  }
  // .header {
  //   margin-right: 50%;
  // }
  .et {
    width: 70px;
    height: 250px;
    margin-right: -120px;
  }
  // .etage {
  //   right: 90px;
  //   width: 5%;
  //   height: 5%;
  // }
  .eta2 {
    top: -70%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta3 {
    top: -100%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta4 {
    top: 460%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
  .eta5 {
    top: 620%;
    left: 55px;
    width: 120%;
    height: 120%;
  }
}
@media screen and (max-width: 768px) {
  .some-modal-content {
    .buttons button {
      margin: 1rem;
      background-color: rgba(0, 0, 0, 0.35);
      :hover::before {
        transform: rotate(360deg);
      }
    }
  }
  .right-answer {
    color: white !important;
    background-color: rgb(0, 255, 21) !important;
  }
  .wrong-answer {
    color: white !important;
    background-color: red !important;
  }
  .statpopup {
    background-color: #fff;
    height: 50%;
  }
  .logo {
    margin-top: 2%;
    margin-left: 20px;
    margin-bottom: -10%;
    width: auto;
    height: 50px;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 40px;
  }
  .closestat {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -5%;
    right: -3%;
  }
  .header {
    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.95);
    }
    // .navbar {
    //   // height: 30px;
    //   // bottom: 5%;
    //   // width: 100%;
    //   // margin-right: 50%;
    //   background: transparent;
    // }
    .router-link-exact-active.is-active {
      color: #ffffff;
      border-bottom: 1px solid;
    }
  }
  .lg {
    background-color: #fff;
    top: 5%;
    width: auto;
    height: 50px;
    position: fixed;
    border-radius: 0 10px 10px 0;
  }
  .answer {
    padding: 10px;
    margin-left: 50px;
    border: 0.5px solid #0071ce;
    border-radius: 10px;
    width: 75%;
    color: #0071ce;
  }
  .answer:hover {
    background-color: #0071ce;
    color: white;
  }
  .h {
    color: #0071ce;
    font-family: 'Robot';
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
  }
  .closebutton {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -12%;
    right: -4%;
  }
  .poquiz {
    margin-top: 1%;
  }
  .quizContent {
    height: 100%;
    margin-right: 30%;
    width: 900px;
    background: white;
    right: 50%;
    // border-radius: 0px 0px 15px 15px;
    aspect-ratio: auto;
  }
  .compteur {
    height: 2%;
    padding: 2px;
    width: 100px;
    background-color: #0071ce;
    color: white;
    font-size: 15px;
    margin-left: 245px;
    border-radius: 5px 0px 0px 5px;
  }
  .plan-popup {
    img {
      width: 100%;
      height: 100%;
    }
  }
  .area {
    cursor: pointer;
  }
  .line {
    margin-left: 14%;
    margin-right: 14%;
    margin-bottom: 5%;
    border: 1px solid #0071ce;
  }
  .loading-bar {
    height: 10px;
    bottom: 0px;
    padding-bottom: 0%;
    // border-radius: 0px 0px 15px 15px;
    background: #0071ce;
  }
  .et {
    width: 70px;
    height: 250px;
    margin-right: -120px;
  }
}
@media screen and (max-width: 480px) {
  .some-modal-content {
    .buttons button {
      margin: 1rem;
      background-color: rgba(0, 0, 0, 0.35);
      :hover::before {
        transform: rotate(360deg);
      }
    }
  }
  .right-answer {
    color: white !important;
    background-color: rgb(0, 255, 21) !important;
  }
  .wrong-answer {
    color: white !important;
    background-color: red !important;
  }
  .statpopup {
    background-color: #fff;
    height: 50%;
  }
  .logo {
    margin-top: 2%;
    margin-left: 20px;
    margin-bottom: -10%;
    width: auto;
    height: 50px;
  }
  .navigation {
    width: 100%;
    position: fixed;
    bottom: 40px;
  }
  
  .header {
    .navbar-dark .navbar-nav .nav-link {
      color: rgba(255, 255, 255, 0.95);
    }
    // .navbar {
    //   // height: 30px;
    //   // bottom: 5%;
    //   // width: 100%;
    //   // margin-right: 50%;
    //   background: transparent;
    // }
    .router-link-exact-active.is-active {
      color: #ffffff;
      border-bottom: 1px solid;
    }
  }
  .lg {
    background-color: #fff;
    top: 5%;
    width: auto;
    height: 50px;
    position: fixed;
    border-radius: 0 10px 10px 0;
  }
  .answer {
    padding: 10px;
    border: 0.5px solid #0071ce;
    border-radius: 10px;
    width: 75%;
    color: #0071ce;
  }
  .answer:hover {
    background-color: #0071ce;
    color: white;
  }
  .h {
    color: #0071ce;
    font-family: 'Robot';
    font-weight: bold;
    text-align: center;
    margin-top: 10%;
  }
  .closebutton {
    border: 3px solid white;
    font-weight: bold;
    background-color: transparent;
    color: white;
    position: absolute;
    top: -12%;
    right: -4%;
  }
  .poquiz {
    margin-top: 1%;
  }
  .quizContent {
    height: 100%;
    margin-right: 30%;
    width: 900px;
    background: white;
    right: 50%;
  }
  .compteur {
    height: 2%;
    padding: 4px;
    width: 120px;
    background-color: #0071ce;
    color: white;
    font-size: 15px;
    // margin-left: 70%;
    border-radius: 5px 0px 0px 5px;
  }
  .plan-popup {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .area {
    cursor: pointer;
  }
  .line {
    margin-left: 14%;
    margin-right: 14%;
    margin-bottom: 5%;
    border: 1px solid #0071ce;
  }
  .loading-bar {
    height: 10px;
    bottom: 0px;
    padding-bottom: 0%;
    // border-radius: 0px 0px 15px 15px;
    background: #0071ce;
  }
  .etage {
    right: 6%;
    width: 100px;
    height: 300px;
  }
}
</style>
