// single pages
import homePage from '../pages/Home.vue'
import Site from '../pages/Site.vue'
import notFoundPage from '../pages/NotFound.vue'

import { DOMAIN_TITLE } from '../.env'

export const routes = [
  {
    path: '/',
    name: 'index',
    component: homePage,
    meta: { title: `${DOMAIN_TITLE} | Home`, hasMobileView: false }
  },
  {
    path: '/site',
    name: 'site',
    component: Site,
    meta: { title: `${DOMAIN_TITLE}`, hasMobileView: false }
  },
  {
    path: '*',
    component: notFoundPage,
    meta: { title: `${DOMAIN_TITLE} | not found`, hasMobileView: false }
  }
]
