<template>
  <div class="center">
    <!--  <h1>404 not found</h1>
    <h2>it seems you're in the wrong page</h2>
    <div>
      <img src="../assets/404.jpg"/>
    </div> -->
    <div class="responsive">
      <div class="center">
        <a target="_blank" href="/">
          <img src="../assets/404.jpg" alt="Forest" width="600" height="400" />
        </a>
      </div>
    </div>
    <div class="desc">it seems you're in the wrong page</div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.center {
  padding-top: 10%;
  padding-left: 30%;
}
.img {
  height: 10%;
  width: 10%;
}
div.gallery img {
  width: 300%;
  height: auto;
}
.desc{
 padding-top:30%;
 padding-left: -10%;
 font-size: 30px;
}
.responsive {
  padding: 0 6px;
  float: left;
  width: 24.99999%;
}
@media only screen and (max-width: 700px) {
  .responsive {
    width: 49.99999%;
    margin: 6px 0;
  }
}
@media only screen and (max-width: 500px) {
  .responsive {
    width: 100%;
  }
}

.clearfix:after {
  content: '';
  display: table;
  clear: both;
}
</style>
