var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.mySwiper)?_c('loader'):_vm._e(),_c('div',{ref:"swiperContainer",staticClass:"swiper-container",style:({ height: _vm.height }),on:{"mouseover":function($event){_vm.mySwiper
        ? (function () {
            _vm.mySwiper.autoplay.stop()
            _vm.progressWidth = 0
            _vm.progressWidthDelay = 500
          })()
        : null},"mouseleave":function($event){_vm.mySwiper
        ? (function () {
            _vm.mySwiper.autoplay.start()
            _vm.progressWidth = 100
            _vm.progressWidthDelay = _vm.mySwiper.params.autoplay.delay
          })()
        : null}}},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.slides),function(slide,index){return _c('slide',{key:index,attrs:{"slide":Object.assign({}, slide, {index: index}),"isActive":_vm.activeIndex === index,"justImage":_vm.justImage,"content":_vm.content[0],"scrollData":_vm.scrollData,"autoplay":true},on:{"imageLoaded":_vm.onImageLoaded}})}),1),(_vm.slides.length > 1)?_c('div',{staticClass:"swiper-pagination",staticStyle:{"visibility":"hidden"}}):_vm._e(),(_vm.slides.length > 1)?_c('div',{staticClass:"swiper-progressBar"},[_c('div',{staticClass:"swiper-bar",style:({
          transition: 'width ' + _vm.progressWidthDelay + 'ms linear',
          width: _vm.progressWidth + '%'
        })})]):_vm._e(),(_vm.mySwiper !== null && _vm.slides.length > 1)?_c('nav',{staticClass:"nav-slit"},[_c('a',{staticClass:"prev",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.activeIndex === 0 ? _vm.mySwiper.slideTo(_vm.slides.length - 1) : _vm.mySwiper.slidePrev()}}},[_vm._m(0),_c('div',[_c('h3',{attrs:{"id":"title-prev"}},[_vm._v(" "+_vm._s(_vm.slides[_vm.activeIndex === 0 ? _vm.slides.length - 1 : _vm.activeIndex - 1].title)+" ")]),_c('img',{attrs:{"id":"thumb-prev","src":_vm.slides[_vm.activeIndex === 0 ? _vm.slides.length - 1 : _vm.activeIndex - 1].thumbUrl,"alt":"Previous thumb"}})])]),_c('a',{staticClass:"next",attrs:{"href":"javascript:void(0)"},on:{"click":function($event){_vm.activeIndex === _vm.slides.length - 1 ? _vm.mySwiper.slideTo(0) : _vm.mySwiper.slideNext()}}},[_vm._m(1),_c('div',[_c('h3',{attrs:{"id":"title-next"}},[_vm._v(" "+_vm._s(_vm.slides[_vm.activeIndex === _vm.slides.length - 1 ? 0 : _vm.activeIndex + 1].title)+" ")]),_c('img',{attrs:{"id":"thumb-next","src":_vm.slides[_vm.activeIndex === _vm.slides.length - 1 ? 0 : _vm.activeIndex + 1].thumbUrl,"alt":"Next thumb"}})])])]):_vm._e()])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon-wrap"},[_c('i',{staticClass:"icon fa fa-angle-left"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"icon-wrap"},[_c('i',{staticClass:"icon fa fa-angle-right"})])}]

export { render, staticRenderFns }