<template>
  <div class="px-0">
    <!-- <AppHeader></AppHeader> -->
  <div class="app-wrapper">
    <div class="row justify-content-md-center">
        <button type="button" class="btn" @click="goTo()">
          start the experience
        </button>
    </div>
  </div>
  </div>
  
</template>

<script>
/* import AppHeader from '../layout/Header' */
export default {
  name: 'IndexPage',
  components: {
    /* AppHeader */
  },
  data() {
    return {
      langue: 'fr',
      visible: true,
      msg: 'KSAMuseum 360°',
      isShownModal: false,
      inputError: false,
      checkboxState: false,

      pagination: {
        limit: 10,
        offset: 0,
        total: 60
      }
    }
  },
  methods: {
    goTo() {
      window.location.href = '/site'
    }
  },
  toggle: function() {
    this.visible = !this.visible
  }
}
</script>

<style lang="scss" scoped>
.navul {
  color: #fff;
  text-align: center;
  position: fixed;
  top: 5%;
  right: 2%;
  animation: vibrator 1s ease-out;
}
.filter {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
  opacity: 1;
}
.img {
  position: fixed;
  top: 0%;
  width: 100%;
  height: 100%;
}
.btn {
  position: fixed;
  font-size: 30px;
  font-weight: 600;
  padding-left: 50px;
  padding-right: 50px;
  border-color: black;
  text-align: center;
  margin: 0;
  top: 50%;
}
.btn:before {
  content: '';
    background: linear-gradient(45deg, #eb5a3d, #6e1846, #f49426, #93bab4, #f9c39c);
    position: absolute;
    top: -2px;
    left:-2px;
    background-size: 400%;
    z-index: -1;
    filter: blur(5px);
    width: calc(100% + 4px);
    height: calc(100% + 4px);
    animation: glowing 20s linear infinite;
    opacity: 0;
    transition: opacity .3s ease-in-out;
    border-radius: 10px;
}
.btn:active{
  color: #fff;
}
.btn:active::after{
  background: transparent;
}
.btn:hover::before{
  opacity:1;
}
.btn:after{
  z-index: -1;
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background: #fff;
    left: 0;
    top: 0;
    border-radius: 10px;
}
@keyframes glowing {
    0% { background-position: 0 0; }
    50% { background-position: 400% 0; }
    100% { background-position: 0 0; }
}
.center {
  display: block;
  margin-top: 20%;
  margin-left: 10%;
  margin-right: 10%;
}
.mg{
  top: 40%;
  left: 10%;
  width: 80%;
  position:fixed;
  animation: vibrator 2s ease-out;
}
.c {
  top: 5%;
  right: 9%;
  position: fixed;
  font-family: 'Roboto';
  color: white;
  font-size: 20px;
  animation: vibrator 1s ease-out;
}
.lg {
  position: absolute;
  background-color: #fff;
  top: 5%;
  width: auto;
  height: 10%;
  position: fixed;
  animation: fade 2s ease-out;
}
.fr {
  position: absolute;
  right: -25%;
  top: 0%;
}
.en {
  position: absolute;
  right: -25%;
  top: 30%;
}
.ar {
  position: absolute;
  right: -25%;
  top: 60%;
}
@keyframes pulse {
  to {
    box-shadow: 0 0 0 5px rgba(255, 255, 255, 0.51);
  }
}
@keyframes fade {
  from {
    opacity: 0;
    transform: translate(-100px);
  }
  to {
    opacity: 1;
    transform: translate(0px);
  }
}
@keyframes vibrator {
  from {
    opacity: 0;
    transform: translateY(-100px);
  }
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

@media screen and (max-width: 768px) {
  .btn {
    font-size: 15px;
    font-weight: 300px;
    top: 65%;
    margin-left: 30%;
  }
}
@media screen and (max-width: 1243px) {
  .header {
    .navbar {
      background-color: rgba(0, 0, 0, 0.75);
    }
  }
}
</style>
