/**
 * import and init global plugins
 */

import Vue from 'vue'

import globalEventBus from '../plugins/globalEventBus'
// import scene from '../plugins/scene'

Vue.use(globalEventBus)
// Vue.use(scene)
